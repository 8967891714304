import QrScanner from "qr-scanner";

const qrCodeScanner = async function (scannerEl) {
  if (!scannerEl) return;

  const goToCardBtn = document.querySelector(".go-to-card");

  function setResult(el, result) {
    const url = new URL(result.data);

    if (url.origin !== location.origin) {
      return confirm("Ми приймаємо QR-коди тільки нашого сервісу.");
    }

    el.classList.remove("disabled");
    el.removeAttribute("aria-disabled");
    el.setAttribute("href", url.pathname);

    setTimeout(async () => {
      await scanner.stop();
      window.location.href = url.pathname;
    }, 500);
  }

  const scanner = new QrScanner(
    scannerEl,
    (result) => setResult(goToCardBtn, result),
    {
      onDecodeError: (error) => {
        console.info(error);
      },
      highlightScanRegion: true,
      highlightCodeOutline: true,
      maxScansPerSecond: 2,
    }
  );

  await scanner.start();
};

export default qrCodeScanner;
