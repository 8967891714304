function phoneMask(phoneInput) {
  if (!phoneInput) return;

  phoneInput.addEventListener("input", (e) => {
    const x = e.target.value
      .replace(/\D/g, "")
      .match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);

    if (!x[1]) return (e.target.value = "+");

    if (!x[2]) return (e.target.value = `+${x[1]}`);

    e.target.value =
      `+${x[1]} (${x[2]}` +
      (x[3] ? `) ${x[3]}` : "") +
      (x[4] ? `-${x[4]}` : "") +
      (x[5] ? `-${x[5]}` : "");
  });
}

function qrcodeColorEditor(qrCodeColorInput, qrCodeBGColorInput, svgElement) {
  if (!qrCodeColorInput) return;

  qrCodeColorInput.addEventListener("change", (e) => {
    svgElement.forEach((svg) => svg.setAttribute("fill", e.target.value));
    svgElement.forEach((svg) => {
      svg
        .querySelectorAll("path.path")
        .forEach((p) => p.setAttribute("stroke", e.target.value));
    });
  });
  qrCodeBGColorInput.addEventListener("change", (e) => {
    svgElement.forEach((svg) =>
      svg.setAttribute("style", `background-color: ${e.target.value}`)
    );
  });
}

function clearAddressFields(removeBtn) {
  if (!removeBtn) return;

  
  removeBtn.forEach((el) => {

    el.addEventListener("click", (e) => {
      // Empty out all related input field values
      e.target
        .closest(".input-group-wrapper")
        .querySelectorAll("input")
        .forEach((i) => (i.value = ""));
      // hide from display. That's important!
      e.target.closest(".input-group-wrapper").classList.add("d-none");
    });
  });
}

function locationAutocomplete(
  locationInput,
  addressInput,
  mapurlInput,
  pidInput,
  latInput,
  lngInput
) {
  if (!locationInput) return;

  function eventHandler(event) {
    if (event.type === "keydown") {
      if (event.keyCode === 13) event.preventDefault();
    } else if (event.type === "change") {
      if (!event.target.value) {
        event.target.parentElement.parentElement
          .querySelectorAll("input")
          .forEach((i) => (i.value = ""));
      }
    }
  }

  // For cases when ONE additional address input added on page
  if (!locationInput.length) {
    // console.log("In here 1");
    const dropdown = new google.maps.places.Autocomplete(locationInput);

    dropdown.addListener("place_changed", () => {
      const place = dropdown.getPlace();
      addressInput.value = place.formatted_address;
      mapurlInput.value = place.url;
      pidInput.value = place.place_id;
      latInput.value = place.geometry.location.lat();
      lngInput.value = place.geometry.location.lng();
    });
    // On 'Enter' pressed escape submitton
    locationInput.addEventListener("keydown", eventHandler);
    locationInput.addEventListener("change", eventHandler);
    // For account page if MORE than one address input on page
  } else {
    // console.log("In here 2");
    locationInput.forEach((lcInput, idx) => {
      const dropdown = new google.maps.places.Autocomplete(lcInput);

      dropdown.addListener("place_changed", () => {
        const place = dropdown.getPlace();
        addressInput[idx].value = place.formatted_address;
        mapurlInput[idx].value = place.url;
        pidInput[idx].value = place.place_id;
        latInput[idx].value = place.geometry.location.lat();
        lngInput[idx].value = place.geometry.location.lng();
      });

      // On 'Enter' pressed escape submitton
      lcInput.addEventListener("keydown", eventHandler);
      lcInput.addEventListener("change", eventHandler);
    });
  }
}

function addAnAddress(addAddressBtn) {
  if (!addAddressBtn) return;

  const locationGroup = document.querySelector("#locations-grounp");

  let id = locationGroup.querySelectorAll("input[id^=location]").length;
  addAddressBtn.addEventListener("click", (e) => {
    e.preventDefault();

    const inputFieldWrapper = document.createElement("div");
    inputFieldWrapper.classList.add("input-group-wrapper");
    inputFieldWrapper.innerHTML = `
      <div class="input-group mb-3">
        <input class="form-control" type="text" id="location_${id}" name="locations[${id}][location][name]" placeholder="Почніть набирати адресу або назву закладу" autocomplete="off">
        <a class="btn btn-outline-danger remove-field"><i class="bi bi-trash3"></i></a>
      </div>
    `;

    const divEl = document.createElement("div");
    divEl.setAttribute("class", "input-group mb-3");
    divEl.innerHTML = `
      <span class="input-group-text">&#x1F4CD;</span>
      <input class="form-control" type="text" id="address_${id}" name="locations[${id}][location][address]" placeholder="Адреса" aria-label="Адреса" readonly tabindex="-1">
      `;

    const divEl2 = document.createElement("div");
    divEl2.setAttribute("class", "input-group mb-3");
    divEl2.innerHTML = `
      <span class="input-group-text">&#x1F517;</span>
      <input class="form-control" type="text" id="mapurl_${id}" name="locations[${id}][location][mapurl]" placeholder="Посилання" aria-label="Посилання" readonly tabindex="-1">
      <input class="form-control d-none" type="text" id="pid_${id}" name="locations[${id}][location][pid]" placeholder="pid" aria-label="pid" readonly>
      <input class="form-control d-none" type="text" id="coordinates_lat_${id}" name="locations[${id}][location][coordinates][0]" placeholder="Lat" aria-label="lat" readonly="">
      <input class="form-control d-none" type="text" id="coordinates_lng_${id}" name="locations[${id}][location][coordinates][1]" placeholder="Lng" aria-label="lng" readonly="">
    `;

    e.target.previousElementSibling.append(inputFieldWrapper);
    inputFieldWrapper.append(divEl);
    inputFieldWrapper.append(divEl2);

    locationAutocomplete(
      inputFieldWrapper.querySelector(`#location_${id}`),
      inputFieldWrapper.querySelector(`#address_${id}`),
      inputFieldWrapper.querySelector(`#mapurl_${id}`),
      inputFieldWrapper.querySelector(`#pid_${id}`),
      inputFieldWrapper.querySelector(`#coordinates_lat_${id}`),
      inputFieldWrapper.querySelector(`#coordinates_lng_${id}`)
    );

    clearAddressFields(inputFieldWrapper.querySelectorAll("a.remove-field"));

    id++;
  });
}

function singleBonusIcon(input) {
  if (!input) return;

  if (input.checked) iconHandler();

  function iconHandler() {
    const option = document.createElement("option");
    option.setAttribute("value", `${bonusEarned.value}`);
    option.innerHTML = String.fromCodePoint(bonusEarned.value);
    option.setAttribute("selected", "");
    bonusPending.style = "background-color: #e9ecef";
    bonusPending.prepend(option);
    bonusPending
      .querySelectorAll("option[value]:not([selected])")
      .forEach((o) => o.setAttribute("disabled", ""));
  }

  bonusEarned.addEventListener("change", function (e) {
    if (input.checked) {
      iconHandler();
      bonusPending
        .querySelectorAll("option[value]:not([selected])")
        .forEach((o) => o.setAttribute("disabled", ""));
      bonusPending.firstChild.nextElementSibling.remove();
    }
  });

  input.addEventListener("change", function (e) {
    if (e.target.checked) {
      iconHandler();
    } else {
      bonusPending.style = "background-color: transparent";
      bonusPending
        .querySelectorAll("option[value]:not([selected])")
        .forEach((o) => o.removeAttribute("disabled"));
      bonusPending.firstChild.remove();
    }
  });
}

function showHide(btn) {
  if (!btn.length) return;

  document.querySelectorAll("[id^='password']").forEach((field) => {
    field.addEventListener("input", (e) => {
      if (e.target.value.length > 5) {
        e.target.nextElementSibling.disabled = false;
      }
    });
  });

  btn.forEach((b) => {
    b.disabled = true;
    b.addEventListener("click", (e) => {
      e.preventDefault();

      const inputField = e.target.parentElement.querySelector("input");

      if (inputField.type === "password") {
        e.target.previousElementSibling.type = "text";
        e.target.querySelector("i").setAttribute("class", "bi bi-eye-slash");
      } else if (inputField.type === "text") {
        e.target.previousElementSibling.type = "password";
        e.target.querySelector("i").setAttribute("class", "bi bi-eye");
      }
    });
  });
}

function saturdaysXcheck(wdSat, weSat) {
  if (!wdSat || !weSat) return;
  
  const toggleCheck = (el, target) =>
    el.target.checked && (target.checked = false);

  weSat.addEventListener("change", (e) => toggleCheck(e, wdSat));
  wdSat.addEventListener("change", (e) => toggleCheck(e, weSat));
}

export {
  phoneMask,
  qrcodeColorEditor,
  locationAutocomplete,
  addAnAddress,
  clearAddressFields,
  singleBonusIcon,
  showHide,
  saturdaysXcheck,
};
