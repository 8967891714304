function geolocation(latInput, lngInput, ...cardActionBtn) {
  if (!latInput || !lngInput) return;

  // TODO: Refactor, looks ugly.
  cardActionBtn = cardActionBtn.filter(btn => btn !== null);

  const cardCreateBlockAlert = document.querySelector(".alert.geolocation-alert");
  const btnValue = cardActionBtn[0].value;

  // const [createCardBtn, updateCardBtn] = cardActionBtn;

  if (cardActionBtn[0].disabled) {
    cardActionBtn[0].value = "Хвилинку...";
  }

  function cpSuccess(pos) {
    const coords = pos.coords;

    lngInput.value = coords.longitude;
    latInput.value = coords.latitude;

    cardActionBtn.forEach((btn) => {
      if (btn) {
        btn.removeAttribute("disabled");
        btn.value = btnValue;
      }
    });

    // console.log("Acc:", coords.accuracy);
  }

  function cpErr(err) {
    console.log(err.code + " " + err.message);

    // ERR CODE: 3 TIMEOUT Geolocation information was not obtained in the allowed time. 
    // https://developer.mozilla.org/en-US/docs/Web/API/GeolocationPositionError/code
    if (err.code === 3) { 
      alert("Час очікування геолокації вичерпано. Сторінка буде перезавантажена.");
      setTimeout(() => {
        location.reload(true);
      }, 500);
    } else {
      cardCreateBlockAlert.classList.remove("d-none");
      lngInput.value = 0;
      latInput.value = 0;
    }

    cardActionBtn.forEach((btn) => {
      if (btn) {
        btn.removeAttribute("disabled");
        btn.value = btnValue;
      }
    });
  }
  const options = {
    enableHighAccuracy: true,
    timeout: 60000,
    maximumAge: Infinity,
  };

  navigator.geolocation.getCurrentPosition(cpSuccess, cpErr, options);
}

export default geolocation;
