import axios from "axios";

const successMessage = `
  <div class="alert alert-success alert-dismissible fade show" role="alert">
    Ваш запит успішно відправленно! Дякуємо за звернення.
    <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
  </div>
`;

const cooldownMessage = `
  <div class="alert alert-warning alert-dismissible fade show" role="alert">
    Схоже ви вже відправили форму раніше. Якщо бажаєте відправити ще раз, будь ласка спробуйте за ~5 хвилин.
    <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
  </div>
`;

const callback = function (form) {
  if (!form) return;

  const messageEl = document.createElement("div");
  
  form.addEventListener("submit", async (event) => {
    event.preventDefault();
    event.stopPropagation();

    const cbFormInfo = JSON.parse(localStorage.getItem("cbFormInfo"));
    const timestampDiff = cbFormInfo ? new Date(Date.now() - cbFormInfo.timestamp).getMinutes() : null;
    
    const formData = {
      name: event.target.name.value,
      phone: event.target.phone.value,
      email: event.target.email.value,
    };

    if (form.checkValidity()) {

      if (cbFormInfo && timestampDiff < 5) {        
        messageEl.innerHTML = cooldownMessage;
        event.target.before(messageEl);
        return;
      }  
      
      form.querySelector("button").disabled = true;

      try {
        const response = await axios.post("/callback", formData, {
          headers: { "Content-Type": "application/json" },
        });

        if (
          response.data.status === 200 &&
          response.data.message === "success"
        ) {
          const cbFormInfo = { submitted: true, timestamp: Date.now() }
          localStorage.setItem("cbFormInfo", JSON.stringify(cbFormInfo));
                    
          messageEl.innerHTML = successMessage;

          event.target.before(messageEl);
          event.target.remove();
        }
      } catch (error) {
        console.log(error);
      }
    }
  });
};

export default callback;
