const swModule = async function () {
  if ("serviceWorker" in navigator) {
    try {
      const registration = await navigator.serviceWorker.register("/sw.js");
      // console.log("SW registered", registration);
    } catch (err) {
      // console.log("SW not registered", err);
    }
  }
};

export default swModule;
