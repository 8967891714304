import axios from "axios";

async function isLoggedIn() {
  try {
    const response = await axios.get("/api/account");
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

async function cardData() {
  const urlPathName = location.pathname;

  try {
    const response = await axios.get(`/api${urlPathName}`);

    if (!response.data) {
      return;
    } else {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
}

// Add localStorage logic here
const setLocalStorage = async function () {
  const loggedInPromise = isLoggedIn();
  const cardDataPromise = cardData();

  const [loggedIn, card] = await Promise.all([
    loggedInPromise,
    cardDataPromise,
  ]);

  if (loggedIn) return;

  if (card.discarded) {
    const cardsData = JSON.parse(localStorage.getItem("cardsData")) || null;

    return;
  }

  const cardsData = JSON.parse(localStorage.getItem("cardsData")) || [];

  const cardDetailsObj = {
    cardStoreId: card.author._id,
    cardStoreName: card.author.name,
    cardStoreSlug: card.author.slug,
    cardStoreBonusAmount: card.author.minAmountTillFree,
    cardId: card._id,
    cardStoreLogo: card.author.storeLogo ? card.author.storeLogo : null,
    cardAmount: card.amount,
  };

  function findObjectByStoreId(arr, storeId) {
    return arr.find((item) => item.cardStoreId === storeId);
  }

  function updateCardsDataArray(arr, obj) {
    const existingObj = findObjectByStoreId(arr, obj.cardStoreId);

    if (existingObj) {
      localStorage.removeItem("cardsData");
      existingObj.cardId = obj.cardId;
      existingObj.cardAmount = obj.cardAmount;
      existingObj.cardStoreName = obj.cardStoreName;
      existingObj.cardStoreSlug = obj.cardStoreSlug;
      existingObj.cardStoreLogo = obj.cardStoreLogo;
      existingObj.cardAmount = obj.cardAmount;
      localStorage.setItem("cardsData", JSON.stringify(cardsData));
    } else {
      localStorage.removeItem("cardsData");
      arr.push(obj);
      localStorage.setItem("cardsData", JSON.stringify(cardsData));
    }
  }

  updateCardsDataArray(cardsData, cardDetailsObj);
};

const checkLocalStorage = function () {
  if (localStorage.hasOwnProperty("cardsData")) {
    const cardsData = JSON.parse(localStorage.getItem("cardsData"));

    return cardsData;
  }
};

const removeCardFromLocalStorage = async function (cardId) {
  if (confirm("Ви впевненні, що бажаєте позбутися цієї картки?")) {
    // First set the card in a DB as a discarded
    try {
      await axios.post(`/api/discard/${cardId}`);
    } catch (error) {
      console.log(error);
    }

    // Then update localstorage
    const cardsData = JSON.parse(localStorage.getItem("cardsData"));
    const existingCard = cardsData.find((itm) => itm.cardId === cardId);
    const updatedCardsData = cardsData.filter(
      (itm, idx) => idx !== cardsData.indexOf(existingCard)
    );

    localStorage.setItem("cardsData", JSON.stringify(updatedCardsData));

    location.reload();
  }
};

export {
  isLoggedIn,
  setLocalStorage,
  checkLocalStorage,
  removeCardFromLocalStorage,
};
