const pullToRefresh = function(card) {
  if (!card) return;

  const pageLoadEl = document.querySelector(".page-reload");
  const standalone = window.matchMedia("(display-mode: standalone)").matches;
  const ios = /iPhone|iPad|iPod/i.test(navigator.userAgent);

  if (!standalone && !ios) return;

  let posStart = 0;
  let posCurrent = 0;

  function reload() {
    pageLoadEl.classList.remove("reload-float-in");
    pageLoadEl.classList.add("reload-float-out");
    
    setTimeout(() => {
      pageLoadEl.classList.remove("reload-float-out");
      window.location.reload();
    }, 1000);
  }

  function handleTouchStart(e) {
    if (typeof e["targetTouches"] !== "undefined") {
      let touch = e.targetTouches[0];
      posStart = touch.screenY;
    } else {
      posStart = e.screenY;
    }
  }

  function handleTouchMove(e) {
    if (typeof e["changedTouches"] !== "undefined") {
      let touch = e.changedTouches[0];
      posCurrent = touch.screenY;

      let changeY = posStart < posCurrent ? Math.abs(posStart - posCurrent) : 0;
      if (window.scrollY < -150 && changeY > 100) {
        pageLoadEl.classList.add("reload-float-in");
      }
    } else {
      posCurrent = e.screenY;
    }
  }

  function handleTouchEnd(e) {
    let changeY = posStart < posCurrent ? Math.abs(posStart - posCurrent) : 0;
    if (window.scrollY < -150 && changeY > 100) reload();

    if (changeY < 100 && pageLoadEl.classList.contains("reload-float-in")) {
      pageLoadEl.classList.remove("reload-float-in");
      pageLoadEl.classList.add("reload-float-out");
      
      setTimeout(() => pageLoadEl.classList.remove("reload-float-out"), 500);
    } 
  }

  document.addEventListener("touchstart", e => handleTouchStart(e));
  document.addEventListener("touchmove", e => handleTouchMove(e));
  document.addEventListener("touchend", e => handleTouchEnd(e));
}

export default pullToRefresh;