const checkOnlineStatus = async () => {
  try {
    const online = await fetch(`../images/1x1.png?hash=${Date.now()}`, {
      mode: "no-cors",
      cache: "no-cache",
    });
    return online.status >= 200 && online.status < 300; // either true or false
  } catch (err) {
    return false; // definitely offline
  }
};

const offlineBadge = (el) => {
  if (!el) return;

  setInterval(async () => {
    const result = await checkOnlineStatus();
    console.log(result);

    if (result) {
    } else {
    }
    // const statusDisplay = document.querySelector(".internet-connection");
    // statusDisplay.textContent = result ? "Online" : "OFFline";
  }, 2000);

  // if (localStorage.getItem("isOffline")) {
  //   el.classList.remove("d-none");
  // } else {
  //   el.classList.add("d-none");
  // }

  // function handleOffline(e) {
  //   if (e.type === "offline") {
  //     console.log(e.type);
  //     localStorage.setItem("isOffline", true);
  //     el.classList.remove("d-none");
  //   } else {
  //     console.log(e.type);
  //     localStorage.removeItem("isOffline");
  //     el.classList.add("d-none");
  //   }
  // }

  // window.addEventListener("offline", (e) => handleOffline(e));
  // window.addEventListener("online", (e) => handleOffline(e));
};

const isPWA = () => {
  const date = new Date();
  const standalone = window.matchMedia("(display-mode: standalone)").matches;

  if (standalone) {
    document.cookie = `isPWA=true; expires=${date.setTime(
      date.getTime() + 365 * 24 * 60 * 60 * 1000
    )}`;
  }
};

const shareCard = (el) => {
  if (!el || !window.matchMedia("(display-mode: standalone)").matches) return;

  el.classList.remove("d-none");

  el.onclick = async function (e) {
    console.log(e);
    if (navigator.share) {
      const shareData = {
        title: `Поділитися карткою від ${e.target.dataset.storename}`,
        text: `Поділитися карткою від ${e.target.dataset.storename}`,
        url: document.location.href,
      };

      try {
        await navigator.share(shareData);
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log("'Поділитися посиланням' не підтримується.");
    }
  };
};

const androidInstall = (btn, promptPopup) => {
  let installPrompt = null;

  window.addEventListener("beforeinstallprompt", (event) => {
    event.preventDefault();
    installPrompt = event;
    // installButton.removeAttribute("hidden");
  });

  btn.addEventListener("click", async () => {
    if (!installPrompt) return;

    const result = await installPrompt.prompt();
    console.log(`Install prompt was: ${result.outcome}`);
    disableInAppInstallPrompt();
  });

  function disableInAppInstallPrompt() {
    installPrompt = null;
    promptPopup.classList.add("d-none");
  }
};

const installAppPromptPopup = async (el) => {
  // If no popup element and if not iOS and if is PWA, do not show this popup
  if (
    !el ||
    // !/iPhone|iPad|iPod/i.test(navigator.userAgent) ||
    window.matchMedia("(display-mode: standalone)").matches
  )
    return;

  // If iOS show iOS install prompt
  if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
    document.querySelector(".ios-install-prompt").classList.remove("d-none");
    // Else prompt Andriod
  } else if (/Android/i.test(navigator.userAgent)) {
    const installedApps = await navigator.getInstalledRelatedApps();
    // If app is already installed, return
    if (installedApps.length !== 0) return;

    document
      .querySelector(".android-install-prompt")
      .classList.remove("d-none");
    const installBtn = document.querySelector("#android-install-app-btn");

    androidInstall(installBtn, el);
  } else {
    return;
  }

  // "install app prompt popup", click on "remind me later" add cookie that'll
  // expire in 72 hrs / 3 days and hide the popup
  document
    .querySelector("#ath-remind-later, #android-remind-later")
    .addEventListener("click", (e) => {
      e.preventDefault();
      e.stopPropagation();

      document.cookie = `appInstallRemindMeLater=ok; path="/"; expires=${new Date(
        Date.now() + 3 * 24 * 60 * 60 * 1000
      ).toUTCString()}`; // Exp cookie in 72hrs/3days

      el.classList.add("d-none");
    });

  // This "if statement" added to prevent displaying popup untill
  // "appInstallRemindMeLater" or if "ATHpopupClose" cookie is present.
  // As soon as one of them is gone, continue with the code evaluation
  if (
    document.cookie.includes("appInstallRemindMeLater") ||
    document.cookie.includes("appInstallPopupClose")
  )
    return;

  // On page load show popup after 3 sec
  setTimeout(() => {
    el.classList.remove("d-none");
  }, 3 * 1000); // After 3 sec

  // On popup click hide it and show again after 1 day
  el.querySelector(".close-install-app-popup").addEventListener(
    "click",
    (e) => {
      el.classList.add("d-none");

      document.cookie = `appInstallPopupClose=ok; path="/"; expires=${new Date(
        Date.now() + 24 * 60 * 60 * 1000
      ).toUTCString()}`; // After 1 day
    }
  );
};

export { offlineBadge, isPWA, shareCard, installAppPromptPopup };
